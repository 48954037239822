<template>
    <modal ref="modalProductoEliminado" titulo="Producto Eliminado" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0 mb-3">
            <div class="col-12 text-center text-general f-17 f-500">
                ID {{ producto.id_asignado }}
            </div>
            <div class="col-12 text-center text-general f-17 f-500">
                {{ producto.nombre }}
            </div>
        </div>
        <div class="row mx-0 bg-light-f5 border px-2 py-2 br-8">
            <div class="col-auto">
                <img :src="responsable.foto" width="51" height="51" class="obj-cover border rounded-circle" />
            </div>
            <div class="col-3 f-14 text-general ">
                <p class="f-500">
                    Eliminado por: 
                </p>
                <p> {{ responsable.nombre }} </p>
                <p> {{ producto.created_at | helper-fecha('DD MMM YYYY - H:mm a') }} </p>
            </div>
            <div class="col f-14 text-general ">
                <p class="f-500">
                    Justificación
                </p>
                <p>
                    {{ producto.justificacion }}
                </p>
            </div>
        </div>
        <div class="row mx-0 my-3">
            <div class="col d-flex my-2">
                <img :src="producto.foto" class="obj-cover img_producto border br-12" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col-12 my-1">
                            <p class="text-general f-14 f-600">
                                Creado: 
                            </p>
                            <p class="text-general f-14">
                                {{ producto.creador }}
                            </p>
                            <p class="text-general f-14">
                                {{ producto.fecha_creado | helper-fecha('DD MMM YYYY - H:mm a') }}
                            </p>
                        </div>
                        <div class="col-12 my-1">
                            <p class="text-general f-14 f-600">
                                Última actualización: 
                            </p>
                            <p class="text-general f-14">
                                {{ producto.editor }}
                            </p>
                            <p class="text-general f-14">
                                {{ producto.fecha_updated | helper-fecha('DD MMM YYYY - H:mm a') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col my-2">
                <p class="f-14 text-general">
                    Unidades de producto por estados hasta la eliminación
                </p>
                <div class="d-flex mx-0 mt-2">
                    <div class="col-auto" />
                    <div v-for="(icon, i) in icons" :key="i" class="col">
                        <i :class="`icon-${icon.icon} text-general f-20`" />
                    </div>
                </div>
                <div class="d-middle mx-0 mt-1">
                    <div class="col-auto px-1">
                        <i class="icon-leechero-cap f-18 text-general" />
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_carrito }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_sin_confirmar }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_confirmado }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_alistando }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_empacado }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_enviado }}
                    </div>
                </div>
                <div class="row mx-0 border-black" />
                <div class="d-middle mx-0 mt-1">
                    <div class="col-auto px-1">
                        <i class="icon-cliente f-18 text-general" />
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_carrito_cliente }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_sin_confirmar_cliente }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_confirmado_cliente }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_alistando_cliente }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_empacado_cliente }}
                    </div>
                    <div class="col text-general">
                        {{ producto.cantidad_enviado_cliente }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <div class="row mx-0 align-items-center">
                    <div class="col text-general f-500 f-14">
                        Stock hasta la eliminación
                    </div>
                    <div class="col-2">
                        <i class="icon-warehouse f-20 text-general" />
                    </div>
                    <div class="col-2">
                        <i class="icon-leechero-cap f-20 text-general" />
                    </div>
                </div>
                <div v-for="(cedis,index) in stock" :key="index" class="row mx-0 my-2 text-general">
                    <div class="col f-15">
                        {{ cedis.cedis.nombre }}
                    </div>
                    <div class="col-2 text-center">
                        {{ cedis.cantidad_cedis }}
                    </div>
                    <div class="col-2 text-center">
                        {{ cedis.cantidad_cliente }}
                    </div>
                </div>
            </div>
            <div class="col px-0">
                <p class="text-general f-14 f-500">
                    Ventas a leecheros hasta la fecha de eliminación {{ producto.created_at | helper-fecha('DD MMM YYYY - H:mm a') }}
                </p>
                <div class="row mx-0 my-1">
                    <div class="col pl-0 d-flex">
                        <div class="bg-light-f5 border br-10 px-1 d-middle text-general">
                            <div class="col">
                                Último Mes
                            </div>
                            <div class="col-auto f-18 f-600">
                                {{ producto.ventas_mes_cantidad }}
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 border br-10 px-1 d-middle text-general">
                            <div class="col-auto f-18">
                                {{ agregarSeparadoresNumero(producto.ventas_mes_dinero,0) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-1">
                    <div class="col pl-0 d-flex">
                        <div class="bg-light-f5 border br-10 px-1 d-middle text-general">
                            <div class="col pr-0">
                                Toda la vida del producto
                            </div>
                            <div class="col-auto f-18 f-600">
                                {{ producto.ventas_vida_cantidad }}
                            </div>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="bg-light-f5 border br-10 px-1 d-middle text-general">
                            <div class="col-auto f-18">
                                {{ agregarSeparadoresNumero(producto.ventas_vida_dinero,0) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ProductosEliminados from "~/services/configurar/admin/retiros/productosEliminados";
import {mapGetters} from 'vuex'
export default {
    data(){
        return {            
            img_producto: 'https://vixark.b-cdn.net/lp-i-i-p/mini-brownies-rellenos-bimbo-220g-arequipe.jpg',
            icons: [
                {icon: 'cart-outline', id: 1},
                {icon: 'receipt', id: 2},
                {icon: 'ok-circled', id: 3},
                {icon: 'cart-arrow-down', id: 4},
                {icon: 'shopping-outline', id: 5},
                {icon: 'bike', id: 6}
            ],
            id_producto: null,            
            producto:{
                imagen:'',
                creador:'',
                created_at:'',
                editor:'',
                updated_at:'',
            },
            responsable:{
                nombre:'',
                foto:'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
            },
            stock:[]
        }        
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },
    methods: {
        toggle(producto){
            this.id_producto = producto.id_asignado
            this.producto = { ...producto }
            this.eliminarDetalleProducto()
            this.$refs.modalProductoEliminado.toggle();
        },
        async eliminarDetalleProducto(){
            try {
                let params = {
                    id_moneda: this.id_moneda
                }
                const { data } = await ProductosEliminados.GetProducto(this.id_producto, params)

                this.producto = data.producto
                this.responsable = data.responsable
                this.stock = data.stock
            } catch (e){
                this.error_catch(e)
            }
        },
    },    
}
</script>
<style lang="scss" scoped>
.img_producto{
    width: 118px;
    height: 118px;
}
</style>